import React, { useEffect, useState } from 'react';
import {
  Col,
  Row,
  Form,
  Button,
  Typography,
  Divider,
  Modal,
  Input,
  Select,
  notification,
  DatePicker,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import useAuth from '../../hooks/useAuth';
import { updatecontractor } from '../../services/auth';
import { Email } from '../../services/auth';
import { contractorCreate, contractorUpdate } from '../../services/auth';
import UploadDocuments from '../Contractor/UploadDocuments';
import { color } from 'echarts';

const { Title } = Typography;
const { Option } = Select;

const ContractorSummary = ({
  onOk,

  open,
  onCancel,
  initialValues,
}) => {
  const navigate = useNavigate();
  const formRef = React.useRef();
  const { user, userConfig } = useAuth();

  const isDisabled = userConfig.role == 'approver';
  const LocalAdminGrayOut = userConfig.role === 'approver';

  const [employmentStatus, setEmploymentStatus] = useState(
    initialValues?.statusToBeImposed
  );
  const [blockType, setBlockType] = useState(
    initialValues?.blockType || 'permanent'
  );

  const handleStatusChange = (value) => {
    setEmploymentStatus(value);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    formRef.current?.setFieldsValue(initialValues);
    formRef.current?.setFieldsValue({"jobOrderNumber" : null})
    setEmploymentStatus(initialValues?.statusToBeImposed);
    setBlockType(initialValues?.blockType || 'permanent');
  }, [initialValues]);

  const onFinish = (values) => {
    const _id = initialValues._id;
    delete initialValues._id;
    values.status = 'Active';

    if (
      values.statusToBeImposed != 'blocked' &&
      values.blockType != 'temporary'
    ) {
      values.statusToDate = null;
    }

    if (values.statusToBeImposed != initialValues?.statusToBeImposed) {
      values.statusEdited = true;
    }

    const selectedJobIndex = initialValues.addJobDetails.findIndex(
      (job) => job.jobordernumber === selectedJobOrderNumber
    );

    if (selectedJobIndex !== -1) {
      initialValues.addJobDetails[selectedJobIndex].newStatus = 'Active';
    }

    updatecontractor({
      ...initialValues,
      ...values,
      selectedJobIndex,
      docId: _id,
    })
      .then((res) => {
        // if (initialValues.addJobDetails.length < 2) {
        //   values._id = _id;
        //   contractorCreate(values);
        // } else {
        //   contractorUpdate({ selectedJobIndex, id: _id });
        // }
        notification.success({ message: 'Approval Updated Successfully' });
      })
      .catch((err) => {
        notification.error({ message: 'Error while approving' });
      });

    Email({
      // medicalCheckupDate: values.medicalCheckupDate,
      workmenName: initialValues.managerName,
      templateId: 'NOTIFICATION_APPROVAL_CONTARCTOR_AGENCY_PROFILE_VYN',
      orgId: userConfig.orgId,
      level: null,
      role: 'contractor', //userConfig.role
      locationId: null,
      correctRecipientEmail: initialValues.emailId,
    })
      .then((res) => {
        notification.success({ message: res.data.message });
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
      });

    window.location.reload();
  };
  /* eslint-disable */
  //const { user, userConfig } = useAuth();

  const [data, setData] = React.useState(initialValues);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [rejectReason, setRejectReason] = useState('');
  const [isOkButtonDisabled, setIsOkButtonDisabled] = useState(true);

  useEffect(() => {
    // Enable the OK button only if rejectReason length is greater than 0
    setIsOkButtonDisabled(rejectReason.length === 0);
  }, [rejectReason]);

  const onSubmit = () => {
    formRef.current?.submit();
  };
  const cancelForReject = () => {
    // Close the modal without taking any action
    setIsModalVisible(false);
  };
  const handleOk = () => {
    // Reject(rejectReason);
    Email({
      // medicalCheckupDate: values.medicalCheckupDate,
      // workmenName: value.managerName,
      templateId: 'NOTIFICATION_REJECTION_CONTARCTOR_AGENCY_PROFILE_VYN',
      orgId: userConfig.orgId,
      level: null,
      role: 'contractor', //userConfig.role
      locationId: null,
      rejectReason: rejectReason,
      correctRecipientEmail: initialValues.emailId,
    })
      .then((res) => {
        notification.success({ message: res.data.message });
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
      });

    // Close the modal
    setIsModalVisible(false);
    //Reject status//for code merging
    delete initialValues._id;

    initialValues.status = 'Rejected';
    updatecontractor(initialValues)
      .then((res) => {
        notification.success({ message: 'Approval Updated Successfully' });
        // navigate('/');
      })
      .catch((err) => {
        notification.success({ message: 'Approval Updated Successfully' });
        // navigate('/');
      });
  };

  const handleReject = () => {
    // Show the popup when the "Reject" button is clicked
    setIsModalVisible(true);
    onCancel();
  };
  const handleCancel1 = () => {
    // Close the modal without showing the popup when the "X" close button or "Cancel" button is clicked
    setIsModalVisible(false);
    formRef.current?.resetFields();
    setSelectedJobOrderNumber(null);
    onCancel();
  };
  const [selectedJobOrderNumber, setSelectedJobOrderNumber] = useState('' | null);
  const [disableApprove, setdisableApprove] = useState(false);

  const handleJobOrderNumberChange = (value) => {
    //jobordernumber
    setSelectedJobOrderNumber(value);
    console.log(initialValues)
    if(initialValues?.status === "Pending") setdisableApprove(false);
    else {
      initialValues.addJobDetails?.map((j) => {
        if (j.jobordernumber == value) {
          if (j.newStatus != 'Pending') setdisableApprove(true);
          //Changes Made by viresh
          // if (j.status != 'Pending') setdisableApprove(true);
          else setdisableApprove(false);
        }
      });
    }
    //
    // const selectedJobIndex = initialValues.addJobDetails.indexOf(value);
    //
  };

  const handleFieldChange = async (changedField, allField) => {
    if (changedField[0]?.name[0] === 'blockType') {
      setBlockType(changedField[0]?.value);
    }
  };

  //console.log('initialValuesssss', initialValues);

  return (
    <div>
      <Modal
        open={open}
        width={1100}
        onOk={onSubmit}
        // onCancel={_onCancel}
        // okText={isEdit ? 'Save' : 'Add'}
        onCancel={handleCancel1}
        okText={'Approve'}
        cancelText={'Reject'}
        // footer={userConfig.role === 'ir' ? null : undefined}
        cancelButtonProps={{ onClick: handleReject }}
        okButtonProps={{
          disabled:
          disableApprove || selectedJobOrderNumber == '',
        }}>
        <Form
          initialValues={initialValues}
          ref={formRef}
          onFinish={onFinish}
          onFieldsChange={handleFieldChange}>
          <Row class='row'>
            <Col span={12} class='column'>
              <Title level={5} style={{ color: 'red' }}>
                Contractor Agency Detail
              </Title>
              <Row>
                <span>Principal Emp Code</span>
                <span class='span2'>:</span>
                <span class='span3'>{userConfig.orgId}</span>
              </Row>
              <Row>
                <span>Contractor Name</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.name}</span>
              </Row>
              <Row>
                <span>Contractor Code</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.id}</span>
              </Row>
              <Row>
                <span>Contractor Foreign Number</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.foreignNumber}</span>
              </Row>
              <Row>
                <span>Manager Name</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.managerName}</span>
              </Row>
              <Row>
                <span>Manager Mobile No.</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.mobileNo}</span>
              </Row>
              <Row>
                <span>Manager Mail Id</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.emailId}</span>
              </Row>
              {/* <Row>
                <span>SAP Vendor Code</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.sapVendorCode}</span>
              </Row> */}
              <Row>
                <span>Owner Name</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.ownerName}</span>
              </Row>
              <Row>
                <span>Owner Contact No.</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.ownerContactNo}</span>
              </Row>
              <Row>
                <span>Owner Mail Id</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.ownerEmailId}</span>
              </Row>
              <Row>
                <span>Location</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.location}</span>
              </Row>
              <Row>
                <span>Type Of Contractor</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.typeOfContractor}</span>
              </Row>
              <Row>
                <span>Proprietorship</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.proprietorship}</span>
              </Row>
              <Row>
                <span>PAN</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.pan}</span>
              </Row>
              {/* <Row>
                <span>Service Tax Number</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.serviceTaxNumber}</span>
              </Row> */}
              <Row>
                <span>Nature Of Work</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.natureOfWork}</span>
              </Row>
              <Row>
                <span>GST No.</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.gstNo}</span>
              </Row>
              {/* <Row>
                <span>VAT No.</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.vatNo}</span>
              </Row> */}
              <Divider>Local Address</Divider>

              <Row>
                <span>Address1</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.address1}</span>
              </Row>
              <Row>
                <span>Address2</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.address2}</span>
              </Row>
              <Row>
                <span>Address3</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.address3}</span>
              </Row>
              <Row>
                <span>State</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.state}</span>
              </Row>
              <Row>
                <span>Village</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.village}</span>
              </Row>
              <Row>
                <span>Taluka</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.taluka}</span>
              </Row>
              <Row>
                <span>District</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.district}</span>
              </Row>
              <Row>
                <span>Tehsil</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.tehsil}</span>
              </Row>
              <Row>
                <span>City</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.city}</span>
              </Row>
              <Row>
                <span>Country</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.country}</span>
              </Row>
              <Row>
                <span>Pincode</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.pincode}</span>
              </Row>

              <Divider>Permanent Address</Divider>

              <Row>
                <span>Address1</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.paddress1}</span>
              </Row>
              <Row>
                <span>Address2</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.paddress2}</span>
              </Row>
              <Row>
                <span>Address3</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.paddress3}</span>
              </Row>
              <Row>
                <span>State</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.pstate}</span>
              </Row>
              <Row>
                <span>Village</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.pvillage}</span>
              </Row>
              <Row>
                <span>Taluka</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.ptaluka}</span>
              </Row>
              <Row>
                <span>District</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.pdistrict}</span>
              </Row>
              <Row>
                <span>Tehsil</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.ptehsil}</span>
              </Row>
              <Row>
                <span>City</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.pcity}</span>
              </Row>
              <Row>
                <span>Country</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.pcountry}</span>
              </Row>
              <Row>
                <span>Pincode</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.ppincode}</span>
              </Row>

              <Divider>Contractor Corresponding Local Address</Divider>

              <Row>
                <span>Address1</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.caddress1}</span>
              </Row>
              <Row>
                <span>Address2</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.caddress2}</span>
              </Row>
              <Row>
                <span>Address3</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.caddress3}</span>
              </Row>
              <Row>
                <span>State</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.cstate}</span>
              </Row>
              <Row>
                <span>Village</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.cvillage}</span>
              </Row>
              <Row>
                <span>Taluka</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.ctaluka}</span>
              </Row>
              <Row>
                <span>District</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.cdistrict}</span>
              </Row>
              <Row>
                <span>Tehsil</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.ctehsil}</span>
              </Row>
              <Row>
                <span>City</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.ccity}</span>
              </Row>
              <Row>
                <span>Country</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.ccountry}</span>
              </Row>
              <Row>
                <span>Pincode</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.cpincode}</span>
              </Row>

              <Row>
                <span>Contact No.</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.contactNo}</span>
              </Row>
              <Row>
                <span>Email Id</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.agencyEmailId}</span>
              </Row>
              {/* <Row>
                <span>Choose Logo</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.logoFile}</span>
              </Row> */}
              {/* <Row>
                <span>Upload document</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.uploadDocument}</span>
              </Row> */}
              <Row>
                <span>User Id</span>
                <span class='span2'>:</span>
                <span class='span3'>{initialValues?.userid}</span>
              </Row>
            </Col>

            <Col span={12} class='column2' style={{ paddingLeft: '50px' }}>
              <Title level={5} style={{ color: 'red' }}>
                Job Order Details
              </Title>
              {/* <Row>
                <Form.Item name='jobOrderNumber' label='Job Order Number'>
                  <Select size='large' width={200}>
                    {initialValues?.addJobDetails.map((option) => (
                      <Option
                        width={300}
                        key={option.jobordernumber}
                        value={option.jobordernumber}>
                        {option.jobordernumber}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Row> */}
              {/* <Divider>Select Job Order Number</Divider> */}
              <Row>
                <Form.Item
                  name='jobOrderNumber'
                  label='Job Order Number/LOI Number'
                  rules={
                    [
                      // { required: true, message: 'Enter Job Order Number' },
                    ]
                  }>
                  <Select
                    placeholder='Select Job Order'
                    size='large'
                    width={200}
                    onChange={handleJobOrderNumberChange}
                    // value={selectedJobOrderNumber}
                    >
                    {initialValues?.addJobDetails?.map((option) => (
                      <Option
                        width={300}
                        style={{
                          color:
                            option.newStatus == 'Pending' ? 'red' : 'green',
                        }}
                        key={option.jobordernumber || ''}
                        value={option.jobordernumber || ''}>
                        {option.jobordernumber}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Row>
              {/* <Row>
                <span>PF of Contractor Establishment</span>
                <span class='span2'>:</span>
                <span class='span3'>
                  {initialValues?.addJobDetails[0]?.pfAccountNo}
                </span>
              </Row> */}
              <Row>
                <span>PF of Contractor Establishment</span>
                <span className='span2'>:</span>
                <span className='span3'>
                  {
                    initialValues?.addJobDetails.find(
                      (detail) =>
                        detail.jobordernumber === selectedJobOrderNumber
                    )?.pfAccountNo
                  }
                </span>
              </Row>
              <Row>
                <span>PF Establishment Sub Code</span>
                <span class='span2'>:</span>
                <span class='span3'>
                  {/* {initialValues?.addJobDetails[0]?.pfEstablishmentSubCode} */}
                  {
                    initialValues?.addJobDetails.find(
                      (detail) =>
                        detail.jobordernumber === selectedJobOrderNumber
                    )?.pfEstablishmentSubCode
                  }
                </span>
              </Row>
              <Row>
                <span>PF Apply Date</span>
                <span class='span2'>:</span>
                <span class='span3'>
                  {/* {initialValues?.addJobDetails[0]?.pfApplyDate
                    ? dayjs(
                        initialValues?.addJobDetails[0]?.pfApplyDate
                      ).format('DD-MM-YYYY')
                    : ''} */}

                  {initialValues?.addJobDetails.find(
                    (detail) => detail.jobordernumber === selectedJobOrderNumber
                  )?.pfApplyDate
                    ? dayjs(
                        initialValues?.addJobDetails.find(
                          (detail) =>
                            detail.jobordernumber === selectedJobOrderNumber
                        )?.pfApplyDate
                      ).format('DD-MM-YYYY')
                    : ''}
                </span>
              </Row>
              <Row>
                <span>ESI of Contractor Establishment</span>
                <span class='span2'>:</span>
                <span class='span3'>
                  {/* {initialValues?.addJobDetails[0]?.esicno} */}
                  {
                    initialValues?.addJobDetails.find(
                      (detail) =>
                        detail.jobordernumber === selectedJobOrderNumber
                    )?.esicno
                  }
                </span>
              </Row>
              {/* <Row>
                <span>ESI Valid From</span>
                <span class='span2'>:</span>

                <span class='span3'>
                  {initialValues?.addJobDetails.find(
                    (detail) => detail.jobordernumber === selectedJobOrderNumber
                  )?.esiValidFrom
                    ? dayjs(
                        initialValues?.addJobDetails.find(
                          (detail) =>
                            detail.jobordernumber === selectedJobOrderNumber
                        )?.esiValidFrom
                      ).format('DD-MM-YYYY')
                    : ''}
                </span>
              </Row> */}
              {/* <Row>
                <span>ESI Valid To</span>
                <span class='span2'>:</span>

                <span class='span3'>
                  {initialValues?.addJobDetails.find(
                    (detail) => detail.jobordernumber === selectedJobOrderNumber
                  )?.esiValidTo
                    ? dayjs(
                        initialValues?.addJobDetails.find(
                          (detail) =>
                            detail.jobordernumber === selectedJobOrderNumber
                        )?.esiValidTo
                      ).format('DD-MM-YYYY')
                    : ''}
                </span>
              </Row> */}
              {/* <Row>
                <span>No.of Workmen Covered Under ESI</span>
                <span class='span2'>:</span>
                <span class='span3'>
                  {
                    initialValues?.addJobDetails.find(
                      (detail) =>
                        detail.jobordernumber === selectedJobOrderNumber
                    )?.nowc
                  }
                </span>
              </Row> */}
              <Row>
                <span>WC/EC Insurance Number</span>
                <span class='span2'>:</span>
                <span class='span3'>
                  {/* {initialValues?.addJobDetails[0]?.wcpn} */}
                  {
                    initialValues?.addJobDetails.find(
                      (detail) =>
                        detail.jobordernumber === selectedJobOrderNumber
                    )?.wcpn
                  }
                </span>
              </Row>
              <Row>
                <span>WC/EC Insurance Registered Date</span>
                <span class='span2'>:</span>

                <span class='span3'>
                  {initialValues?.addJobDetails.find(
                    (detail) => detail.jobordernumber === selectedJobOrderNumber
                  )?.wcecRegisterDate
                    ? dayjs(
                        initialValues?.addJobDetails.find(
                          (detail) =>
                            detail.jobordernumber === selectedJobOrderNumber
                        )?.wcecRegisterDate
                      ).format('DD-MM-YYYY')
                    : ''}
                </span>
              </Row>
              <Row>
                <span>WC/EC Insurance Valid From</span>
                <span class='span2'>:</span>

                <span class='span3'>
                  {initialValues?.addJobDetails.find(
                    (detail) => detail.jobordernumber === selectedJobOrderNumber
                  )?.wcvf
                    ? dayjs(
                        initialValues?.addJobDetails.find(
                          (detail) =>
                            detail.jobordernumber === selectedJobOrderNumber
                        )?.wcvf
                      ).format('DD-MM-YYYY')
                    : ''}
                </span>
              </Row>
              <Row>
                <span>WC/EC Insurance Valid To</span>
                <span class='span2'>:</span>

                <span class='span3'>
                  {initialValues?.addJobDetails.find(
                    (detail) => detail.jobordernumber === selectedJobOrderNumber
                  )?.wcvt
                    ? dayjs(
                        initialValues?.addJobDetails.find(
                          (detail) =>
                            detail.jobordernumber === selectedJobOrderNumber
                        )?.wcvt
                      ).format('DD-MM-YYYY')
                    : ''}
                </span>
              </Row>{' '}
              <Row>
                <span>No. of workers covered Under WC/EC</span>
                <span class='span2'>:</span>
                <span class='span3'>
                  {/* {initialValues?.addJobDetails[0]?.noofWorkmenCovered} */}
                  {
                    initialValues?.addJobDetails.find(
                      (detail) =>
                        detail.jobordernumber === selectedJobOrderNumber
                    )?.noofWorkmenCovered
                  }
                </span>
              </Row>{' '}
              {/* <Row>
                <span>No. of workers used</span>
                <span class='span2'>:</span>
                <span class='span3'>
                  {
                    initialValues?.addJobDetails.find(
                      (detail) =>
                        detail.jobordernumber === selectedJobOrderNumber
                    )?.noOfWorkersUsed
                  }
                </span>
              </Row> */}
              <Row>
                <span>Insurance Company Name</span>
                <span class='span2'>:</span>
                <span class='span3'>
                  {/* {initialValues?.addJobDetails[0]?.insuranceCompanyName} */}
                  {
                    initialValues?.addJobDetails.find(
                      (detail) =>
                        detail.jobordernumber === selectedJobOrderNumber
                    )?.insuranceCompanyName
                  }
                </span>
              </Row>{' '}
              <Row>
                <span>Contractor Code</span>
                <span class='span2'>:</span>
                <span class='span3'>
                  {/* {initialValues?.addJobDetails[0]?.id} */}
                  {
                    initialValues?.addJobDetails.find(
                      (detail) =>
                        detail.jobordernumber === selectedJobOrderNumber
                    )?.id
                  }
                </span>
              </Row>{' '}
              <Row>
                <span>Job Order Number</span>
                <span class='span2'>:</span>
                <span class='span3'>
                  {/* {initialValues?.addJobDetails[0]?.jobordernumber} */}
                  {
                    initialValues?.addJobDetails.find(
                      (detail) =>
                        detail.jobordernumber === selectedJobOrderNumber
                    )?.jobordernumber
                  }
                </span>
              </Row>
              {/* <Row>
                <span>Workmen Approved As Per JO</span>
                <span class='span2'>:</span>
                <span class='span3'>
                  {
                    initialValues?.addJobDetails.find(
                      (detail) =>
                        detail.jobordernumber === selectedJobOrderNumber
                    )?.maxContractors
                  }
                </span>
              </Row> */}
              {/* <Row>
                <span>Average Deployment Per Day</span>
                <span class='span2'>:</span>
                <span class='span3'>
                  {
                    initialValues?.addJobDetails.find(
                      (detail) =>
                        detail.jobordernumber === selectedJobOrderNumber
                    )?.avrageDeployement
                  }
                </span>
              </Row> */}
              <Row>
                <span>Period Of Contractor Start Date</span>
                <span class='span2'>:</span>

                <span class='span3'>
                  {initialValues?.addJobDetails.find(
                    (detail) => detail.jobordernumber === selectedJobOrderNumber
                  )?.periodContractorStartDate
                    ? dayjs(
                        initialValues?.addJobDetails.find(
                          (detail) =>
                            detail.jobordernumber === selectedJobOrderNumber
                        )?.periodContractorStartDate
                      ).format('DD-MM-YYYY')
                    : ''}
                </span>
              </Row>
              <Row>
                <span>Period Of Contractor End Date</span>
                <span class='span2'>:</span>

                <span class='span3'>
                  {initialValues?.addJobDetails.find(
                    (detail) => detail.jobordernumber === selectedJobOrderNumber
                  )?.periodContractorEndDate
                    ? dayjs(
                        initialValues?.addJobDetails.find(
                          (detail) =>
                            detail.jobordernumber === selectedJobOrderNumber
                        )?.periodContractorEndDate
                      ).format('DD-MM-YYYY')
                    : ''}
                </span>
              </Row>
              <Row>
                <span>Labour Licence Numbern</span>
                <span class='span2'>:</span>
                <span class='span3'>
                  {/* {initialValues?.addJobDetails[0]?.lln} */}
                  {
                    initialValues?.addJobDetails.find(
                      (detail) =>
                        detail.jobordernumber === selectedJobOrderNumber
                    )?.lln
                  }
                </span>
              </Row>
              <Row>
                <span>Total Licence Coverage</span>
                <span class='span2'>:</span>
                <span class='span3'>
                  {
                    initialValues?.addJobDetails.find(
                      (detail) =>
                        detail.jobordernumber === selectedJobOrderNumber
                    )?.totalLicenceCoverage
                  }
                </span>
              </Row>
              <Row>
                <span>LL Registered date</span>
                <span class='span2'>:</span>

                <span class='span3'>
                  {initialValues?.addJobDetails.find(
                    (detail) => detail.jobordernumber === selectedJobOrderNumber
                  )?.llregdate
                    ? dayjs(
                        initialValues?.addJobDetails.find(
                          (detail) =>
                            detail.jobordernumber === selectedJobOrderNumber
                        )?.llregdate
                      ).format('DD-MM-YYYY')
                    : ''}
                </span>
              </Row>
              <Row>
                <span>LL Valid From</span>
                <span class='span2'>:</span>

                <span class='span3'>
                  {initialValues?.addJobDetails.find(
                    (detail) => detail.jobordernumber === selectedJobOrderNumber
                  )?.llf
                    ? dayjs(
                        initialValues?.addJobDetails.find(
                          (detail) =>
                            detail.jobordernumber === selectedJobOrderNumber
                        )?.llf
                      ).format('DD-MM-YYYY')
                    : ''}
                </span>
              </Row>
              <Row>
                <span>LL Valid To</span>
                <span class='span2'>:</span>

                <span class='span3'>
                  {initialValues?.addJobDetails.find(
                    (detail) => detail.jobordernumber === selectedJobOrderNumber
                  )?.doe
                    ? dayjs(
                        initialValues?.addJobDetails.find(
                          (detail) =>
                            detail.jobordernumber === selectedJobOrderNumber
                        )?.doe
                      ).format('DD-MM-YYYY')
                    : ''}
                </span>
              </Row>
              <Row>
                <span>Pool of Max No. of Workmen</span>
                <span class='span2'>:</span>
                <span class='span3'>
                  {/* {initialValues?.addJobDetails[0]?.poolWorkmen} */}
                  {
                    initialValues?.addJobDetails.find(
                      (detail) =>
                        detail.jobordernumber === selectedJobOrderNumber
                    )?.poolWorkmen
                  }
                </span>
              </Row>
              {initialValues?.status && initialValues?.status !== 'Pending' && (
                <Col
                  className='gutter-row'
                  span={12}
                  // hidden={
                  //   //userConfig.role == 'approver' ||
                  //   //userConfig.role == 'contractor'
                  // }
                >
                  <Divider>Contract Status</Divider>
                  <Form.Item
                    style={{ marginTop: '10px' }}
                    name='statusToBeImposed'
                    label='Contract Status'
                    // rules={[{ required: true, message: "Enter Status" }]}
                  >
                    <Select
                      //disabled={isDisabled}
                      defaultValue={initialValues?.status}
                      size='large'
                      onChange={handleStatusChange}
                      placeholder='Active'
                      style={
                        LocalAdminGrayOut
                          ? { fontWeight: 'bold', color: 'gray' }
                          : {}
                      }>
                      <Option value='active'>Active</Option>
                      {/* <Option value='retired'>Retired</Option> */}
                      <Option value='terminated'>Terminated</Option>
                      {/* <Option value='rehired'>Rehired</Option> */}
                      {/* <Option value='suspended'>Suspended</Option> */}
                      <Option value='blocked'>Blocked</Option>
                      <Option value='blacklisted'>Blacklisted</Option>
                    </Select>
                  </Form.Item>
                  {employmentStatus === 'active' ? (
                    <Form.Item name='statusFromDate' label='Active Date'>
                      <DatePicker
                        size='large'
                        style={{ width: '100%' }}
                        format='DD-MM-YYYY'
                      />
                    </Form.Item>
                  ) : null}
                  {employmentStatus === 'retired' ? (
                    // <Form.Item name='statusFromDate' label='Retired Date'>
                    //   <DatePicker
                    //     size='large'
                    //     style={{ width: '100%' }}
                    //     format='DD-MM-YYYY'
                    //   />
                    // </Form.Item>
                    <Form.Item
                      name='statusFromDate'
                      label='Retired Date'
                      rules={[
                        {
                          required: true,
                          message: 'Please select a retired date',
                        },
                        {
                          type: 'object',
                          message: 'Please select a valid date',
                        },
                      ]}>
                      <DatePicker
                        size='large'
                        style={{ width: '100%' }}
                        format='DD-MM-YYYY'
                      />
                    </Form.Item>
                  ) : null}
                  {employmentStatus === 'terminated' ? (
                    // <Form.Item name='statusFromDate' label='Terminated Date'>
                    //   <DatePicker
                    //     size='large'
                    //     style={{ width: '100%' }}
                    //     format='DD-MM-YYYY'
                    //   />
                    // </Form.Item>
                    <Form.Item
                      name='statusFromDate'
                      label='Terminated Date'
                      rules={[
                        {
                          required: true,
                          message: 'Please select a terminated date',
                        },
                        {
                          type: 'object',
                          message: 'Please select a valid date',
                        },
                      ]}>
                      <DatePicker
                        size='large'
                        style={{ width: '100%' }}
                        format='DD-MM-YYYY'
                      />
                    </Form.Item>
                  ) : null}
                  {employmentStatus === 'rehired' ? (
                    // <Form.Item name='statusFromDate' label='Rehired Date'>
                    //   <DatePicker
                    //     size='large'
                    //     style={{ width: '100%' }}
                    //     format='DD-MM-YYYY'
                    //   />
                    // </Form.Item>
                    <Form.Item
                      name='statusFromDate'
                      label='Rehired Date'
                      rules={[
                        {
                          required: true,
                          message: 'Please select a rehired date',
                        },
                        {
                          type: 'object',
                          message: 'Please select a valid date',
                        },
                      ]}>
                      <DatePicker
                        size='large'
                        style={{ width: '100%' }}
                        format='DD-MM-YYYY'
                      />
                    </Form.Item>
                  ) : null}
                  {employmentStatus === 'suspended' ? (
                    <div>
                      <Form.Item
                        name='statusFromDate'
                        label='Suspended From Date'
                        rules={[
                          {
                            required: true,
                            message: 'Please select a from date',
                          },
                        ]}>
                        <DatePicker
                          style={{ width: '100%' }}
                          format='DD-MM-YYYY'
                        />
                      </Form.Item>
                      <Form.Item
                        name='statusToDate'
                        label='Suspended To Date'
                        rules={[
                          {
                            required: true,
                            message: 'Please select a to date',
                          },
                        ]}>
                        <DatePicker
                          style={{ width: '100%' }}
                          format='DD-MM-YYYY'
                        />
                      </Form.Item>
                    </div>
                  ) : null}
                  {employmentStatus === 'blocked' ? (
                    <div>
                      <Form.Item
                        name='blockType'
                        label='Block Type'
                        rules={[
                          {
                            required: true,
                            message: 'Please select Dropdown',
                          },
                        ]}>
                        <Select>
                          <Option value='permanent'>Permanent</Option>
                          <Option value='temporary'>Temporary</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name='statusFromDate'
                        label='Blocked From Date'
                        rules={[
                          {
                            required: true,
                            message: 'Please select a from date',
                          },
                        ]}>
                        <DatePicker
                          style={{ width: '100%' }}
                          format='DD-MM-YYYY'
                        />
                      </Form.Item>
                      {blockType == 'temporary' && (
                        <Form.Item
                          name='statusToDate'
                          label='Blocked To Date'
                          rules={[
                            {
                              required: true,
                              message: 'Please select a to date',
                            },
                          ]}>
                          <DatePicker
                            style={{ width: '100%' }}
                            format='DD-MM-YYYY'
                          />
                        </Form.Item>
                      )}
                    </div>
                  ) : null}
                  {employmentStatus === 'blacklisted' ? (
                    // <Form.Item name='statusFromDate' label='Terminated Date'>
                    //   <DatePicker
                    //     size='large'
                    //     style={{ width: '100%' }}
                    //     format='DD-MM-YYYY'
                    //   />
                    // </Form.Item>
                    <Form.Item
                      name='statusFromDate'
                      label='Blacklisted Date'
                      rules={[
                        {
                          required: true,
                          message: 'Please select a Blacklisted date',
                        },
                        {
                          type: 'object',
                          message: 'Please select a valid date',
                        },
                      ]}>
                      <DatePicker
                        size='large'
                        style={{ width: '100%' }}
                        format='DD-MM-YYYY'
                      />
                    </Form.Item>
                  ) : null}
                </Col>
              )}
            </Col>
          </Row>
          <Row class='row'>
            <Col span={'24'}>
              {initialValues?.location && (
                <UploadDocuments
                  personalDetails={{ location: initialValues?.location }}
                  documents={initialValues?.uploadDocument}
                  setDocuments={() => {}}
                  disableUpload={true}
                />
              )}
            </Col>
          </Row>
          <Divider />
          {/* <Form.Item style={{ float: 'right', marginLeft: '10px' }}>
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
          </Form.Item> */}
        </Form>
      </Modal>
      <Modal
        // title="Reject Reason"
        visible={userConfig.role === 'approver' ? isModalVisible : null}
        onOk={handleOk}
        onCancel={cancelForReject}
        okButtonProps={{ disabled: isOkButtonDisabled }} // Control the disabled state of the OK button
      >
        <Form layout='vertical'>
          <Form.Item
            label={
              <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                Reason for Rejection
              </span>
            }
            required
            rules={[
              {
                required: true, // Validation message if the field is empty
              },
            ]}>
            <Input
              placeholder='Enter reason for rejection'
              value={rejectReason}
              onChange={(e) => setRejectReason(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ContractorSummary;
